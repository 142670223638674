<!--素材中心页面-->
<template>
  <div id="appMain">
    <PanelHeader></PanelHeader>
    <div class="box">
      <div class="box_content">
        <div class="navigation">
          <div @click="router.go(-1)">素材中心</div>
          <i class="iconfont icon-xiangyoussku"></i>
          <span>图片详情</span>
        </div>
        <div class="infoBox">
          <div class="infoImg">
              <div class="infoImg_img" :style="{ backgroundImage: `url(${infoData.coverimg})` }"></div>
          </div>
          <div class="infoText">
            <span class="title">{{infoData.title}}</span>
            <div class="copyright">
              <span class="commercial"><i class="iconfont icon-yunxu"></i> 商用无忧</span>  可用于<span class="personal" >个人商用/企业商用</span>
            </div>
            <div class="explain">
              <div class="explain_box">
                <div class="explain_title">栏目名称</div><div class="explain_content">{{infoData.group_name}}</div>
              </div>
              <div class="explain_box">
                <div class="explain_title">图片描述</div><div class="explain_content" v-html="infoData.descript"></div>
              </div>
            </div>
            <div class="btn" @click="cancelFavorite">
              <span v-if="infoData.like_state==0">收藏</span>
              <span v-else >取消收藏</span>
            </div>
            <div class="infoImg_label">
              <div v-for="item in infoData.tag">
                <NuxtLink :to="{name:'materialCenter',query:{k:item,p:1}}">{{item}}</NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <h2>相似推荐</h2>
        <similar-recommendations :similarRecommendations="imgList" :key="imgList" :type="type"></similar-recommendations>
        <footer class="panel-footer">
          <public-footer></public-footer>
        </footer>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PanelHeader from "/layout/components/PanelHeader.vue";
import SimilarRecommendations from "./similarRecommendations.vue";
import {getMaterialImgInfo, savelikestate} from "../../api/apiconfig/main";
import {getLocal} from "../../utils/local";
import {ElMessage} from "element-plus";
import PublicFooter from "@/src/components/PublicFooter/index";
import water from '../../public/img/water.svg';
const router = useRouter()
const route = useRoute()
const imgList = ref([])
const infoData = ref({})
const tid = ref()
const type = ref()
const data = ref([]);
const materialImgInfo =  (async ()=>{
  data.value = route.params
  tid.value = data.value.id
  type.value = data.value.type
  const response = await getMaterialImgInfo({tid:data.value.id,type:data.value.type})
  if(response.data.length!==0){
    imgList.value = response.data.list
    infoData.value = response.data.data
  }
})
await materialImgInfo()
const typeId = ref(2);
//收藏/取消收藏接口
const cancelFavorite = async ()=>{
  switch (type.value) {
    case '1' :
      typeId.value = 3
      break
    case '2' :
      typeId.value = 2
      break
    case '3' :
      typeId.value = 4
      break
  }
  const response  = await savelikestate({tid:tid.value,uid:getLocal('uid'),type:typeId.value})
  if(response===1){
    materialImgInfo()
    ElMessage.success('操作成功')
  }else{
    ElMessage.error('操作失败')
  }
}
const type_name = ref('图片');
if(type.value == 1){
  type_name.value = '图片';
}else if(type.value == 2){
  type_name.value = '素材';
}else{
  type_name.value = '字体';
}

await useHead({
  title:infoData.value.title+type_name.value+'_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
  meta:[
    {hid:'keywords',name:'keywords','content':infoData.value.title+type_name.value+',松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站'},
    {hid:'description',name:'description','content':'松鼠快图'+infoData.value.title+type_name.value+'满足在线设计图片的需求。'},
  ]
})

</script>
<style>
html,
body,
#app,
#__nuxt {
  height: 100%;
}
</style>
<style lang='less' scoped>
#appMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1200px;
  .box{
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    .box_content{
      width: 80%;
      .navigation{
        margin-top: 20px;
        width: 100%;
        height: 40px;
        align-items: center;
        display: flex;
        color: #8693ab;
        .navigation_return{
          color: #8693ab;
          text-decoration: none;
          font-size: 16px;
        }
        .navigation_return:hover{
          color: black;
          cursor: pointer;
          font-size: 16px;
        }
        i{
          font-size: 12px;
          margin: 0 5px;
        }
        span{
          color: black;
          font-size: 16px;
        }
        div{
          cursor: pointer;
        }
      }
      .infoBox{
        width: 100%;
        display: flex;
        //justify-content: space-between;
        .infoImg{
          width: 50%;
          min-width: 50%;
          overflow: hidden;
          border-radius: 4px;
          .infoImg_img{
            position: relative;
            width: 100%;
            height:480px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50%;
            background-color: #F3F4F9;
            // background-image: url("/public/img/water.png");
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          }
          .infoImg_img::before{
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("/public/img/water.svg");
            background-size: contain;
            box-sizing: border-box;
          }
        }
        .infoText{
          margin-left: 80px;
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          user-select: none;
          .copyright{
            height: 22px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #505a71;
            margin: 24px 0;
            .commercial{
              color: #07af4c;
              margin-right: 8px;
            }
            .personal{
              color: #0773fc;
            }
          }
          .explain{
            width: 100%;
            min-width: 144px;
            background-color: #f8f8fb;
            border-radius: 4px;
            margin-bottom: 24px;
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            .explain_box{
              width: 100%;
              display: flex;
              margin-bottom: 10px;
              .explain_title{
                height: 22px;
                margin-right: 24px;
                color: #8693ab;
              }
              .explain_content{
                flex: 1;
                color: #1b2337;
                letter-spacing: 3px;
                line-height: 21px;
                font-size: 14px;
              }
            }
          }
          .title{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #1b2337;
          }
          .btn:hover{
            background-color: var(--el-color-primary-light-3);
          }
          .btn{
            width: 100%;
            height: 56px;
            line-height: 56px;
            background-color: var(--el-color-primary);
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
          }
          .infoImg_label{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            div{
              padding: 0 16px;
              height: 32px;
              background: #f1f3f9;
              font-size: 14px;
              line-height: 32px;
              color: #1b2337;
              text-align: center;
              border-radius: 4px;
              margin: 0 12px 12px 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>