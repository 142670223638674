<template>
  <div class="img-rq">
    <div class="backicon" @click="handleback"><i class="iconfont icon-xiangzuojiantou1"></i></div>
    <img :src="infoData.coverimg">
  </div>
  <section class="img-title-rq">
    <div class="img-title"><i class="iconfont icon-shangyong1"></i>{{infoData.title}}</div>
  </section>
<!--  <div class="desc">作品栏目：{{infoData.group_name}}</div>-->
  <div class="desc">作品详情</div>
  <div class="img-descript">{{infoData.descript}}</div>
  <div class="desc">作品标签</div>
  <div class="img-tag">
    <div class="tag-name" v-for="item in infoData.tag">
      <NuxtLink :to="{name:'materialCenter',query:{k:item,p:1}}" v-if="item !=''">{{item}}</NuxtLink>
    </div>
  </div>
  <div class="xstj">相似推荐</div>
  <like :similarRecommendations="imgList" :key="imgList" :type="type"></like>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import {getMaterialImgInfo, savelikestate} from "@/src/api/apiconfig/main";
import {getLocal} from "@/src/utils/local";
import {ElMessage} from "element-plus";
import like from '@/src/components/wap/materialCenter/like.vue'
import {onMounted} from "vue";
import { useRouter } from 'vue-router';
const router = useRouter();
const route = useRoute()
const imgList = ref([])
const infoData = ref({})
const tid = ref()
const type = ref()
const data = ref([]);
const materialImgInfo =  (async ()=>{
  data.value = route.params
  tid.value = data.value.id
  type.value = data.value.type
  const response = await getMaterialImgInfo({tid:data.value.id,type:data.value.type})
  if(response.data.length!==0){
    imgList.value = response.data.list
    infoData.value = response.data.data
  }
})
const handleback = () =>{
  // 检查是否有上一页历史记录
  if (window.history.state?.back) {
    router.back();
  } else {
    // 如果没有历史记录，则跳转到素材中心
    router.push({
      name: 'materialCenter',
      query: {
        t: type.value,
        p: 1
      }
    });
  }
}
await materialImgInfo()
onMounted(() => {
  const container = document.querySelector("body")
  container.scrollTo(0, 0);
});
const typeId = ref(2);
//收藏/取消收藏接口
const cancelFavorite = async ()=>{
  switch (type.value) {
    case '1' :
      typeId.value = 3
      break
    case '2' :
      typeId.value = 2
      break
    case '3' :
      typeId.value = 4
      break
  }
  const response  = await savelikestate({tid:tid.value,uid:getLocal('uid'),type:typeId.value})
  if(response===1){
    materialImgInfo()
    ElMessage.success('操作成功')
  }else{
    ElMessage.error('操作失败')
  }
}
const type_name = ref('图片');
if(type.value == 1){
  type_name.value = '图片';
}else if(type.value == 2){
  type_name.value = '素材';
}else{
  type_name.value = '字体';
}

await useHead({
  title:infoData.value.title+type_name.value+'_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
  meta:[
    {hid:'keywords',name:'keywords','content':infoData.value.title+type_name.value+',松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站'},
    {hid:'description',name:'description','content':'松鼠快图'+infoData.value.title+type_name.value+'满足在线设计图片的需求。'},
  ]
})
</script>
<style scoped>
.backicon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #88888863;
  line-height: 40px;
  text-align: center;
  position: absolute;
  left: 15px;
  top:15px;
  i{
    color:#fff;
  }
}
.img-rq{
  position: relative;
  min-height: 4.22rem;
  margin-bottom: 0 !important;
  background-color: #f5f5f5;
  background-image:
      linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
      linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
      linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
  background-size: 16px 16px;
  background-position:
      0 0,
      0 8px,
      8px -8px,
      -8px 0;
}
.img-rq img{
  width: 100%;
  vertical-align: top;
}
.tubiao{
  display: flex;
}
.img-title-rq{
  background: #fff;
  margin: 15px 0;
}
.img-title{
  font-size: 18px;
  font-weight: 600;
}
.img-title i{
  font-size: 24px;
  margin-right: 5px;
  color: #da8743;
  margin-left: 5px;
}
.desc{
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  color: #353535;
}
.img-descript{
  font-size: 14px;
  margin-left: 10px;
  margin-top: 5px;
  color: #888;
  line-height: 24px;
  padding-right: 10px;
}
.img-tag{
  display: flex;
  padding-left: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.tag-name{
  padding: 5px 10px;
  background: #f5f6f9;
  margin-right: 5px;
  color:#353535;
  margin-bottom: 5px;
  font-size: 12px;
  border-radius: 5px;
}
.xstj{
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>