<template>
  <div class="waterfall-wrapper">
    <div class="waterfall-container" ref="containerRef">
      <div
          v-for="(item, index) in props.similarRecommendations"
          :key="item.id"
          class="waterfall-item"
          :style="getItemStyle(item, index)"
      >
        <NuxtLink
            :title="item.title"
            :to="'/detailsMaterialCenter/id/'+item.id+'/type/'+props.type"
        >
          <img
              :src="item.coverimg"
              :alt="item.title"
              class="waterfall-img"
              @load="(event) => onImageLoad(event, item)"
              loading="lazy"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';

const props = defineProps({
  similarRecommendations: {
    type: Array,
    default: () => []
  },
  type: {
    type: [Number, String],
    required: true
  }
});

const containerRef = ref(null);
const column = 2; // 固定2列
const gap = 8; // 间距
const positions = ref(new Map()); // 存储每个item的位置
const containerWidth = ref(0);
const loadedImages = ref(new Map()); // 存储已加载图片的尺寸信息

// 计算每个item的位置
const getItemStyle = (item, index) => {
  const itemWidth = containerWidth.value ? (containerWidth.value - gap * (column - 1)) / column : 'auto';

  // 如果有计算好的位置就使用计算好的位置
  if (positions.value.has(item.id)) {
    const pos = positions.value.get(item.id);
    return {
      position: 'absolute',
      left: pos.left + 'px',
      top: pos.top + 'px',
      width: itemWidth + 'px',
      height: pos.height + 'px', // 添加固定高度
      opacity: 1,
      transition: 'all 0.3s ease'
    };
  }

  // 初始位置
  const columnIndex = index % column;
  return {
    position: 'absolute',
    left: columnIndex * (itemWidth + gap) + 'px',
    top: '0px',
    width: itemWidth + 'px',
    opacity: 0,
    transition: 'all 0.3s ease'
  };
};
// 图片加载完成后更新布局
const onImageLoad = (event, item) => {
  const img = event.target;
  loadedImages.value.set(item.id, {
    width: img.naturalWidth,
    height: img.naturalHeight
  });
  // 使用 requestAnimationFrame 来优化性能
  requestAnimationFrame(() => {
    updateLayout();
  });
};

// 更新布局
const updateLayout = () => {
  if (!containerRef.value) return;

  containerWidth.value = containerRef.value.clientWidth;
  const itemWidth = (containerWidth.value - gap * (column - 1)) / column;
  const columnHeights = Array(column).fill(0);
  positions.value.clear();

  // 计算每个图片的位置和高度
  props.similarRecommendations.forEach((item, index) => {
    // 找出最短的列
    const minHeight = Math.min(...columnHeights);
    const columnIndex = columnHeights.indexOf(minHeight);

    // 计算图片高度
    let height;
    if (loadedImages.value.has(item.id)) {
      const imgInfo = loadedImages.value.get(item.id);
      height = itemWidth * (imgInfo.height / imgInfo.width); // 使用实际宽高比
    } else {
      height = itemWidth * 0.75; // 默认比例
    }

    // 计算位置
    const left = columnIndex * (itemWidth + gap);
    const top = columnHeights[columnIndex];

    // 存储位置信息
    positions.value.set(item.id, {
      left,
      top,
      width: itemWidth,
      height: height
    });

    // 更新列高度（加上间距）
    columnHeights[columnIndex] = top + height + gap;
  });

  // 修改容器高度计算
  const maxColumnHeight = Math.max(...columnHeights);
  if (maxColumnHeight > 0) {
    const containerHeight = maxColumnHeight;  // 不再减去gap
    containerRef.value.style.height = `${containerHeight}px`;
  } else {
    containerRef.value.style.height = 'auto';
  }
};
// 监听窗口大小变化
const handleResize = () => {
  requestAnimationFrame(() => updateLayout());
};
// 监听数据变化
watch(() => props.similarRecommendations, () => {
  nextTick(() => {
    updateLayout();
  });
}, { immediate: true });

onMounted(() => {
  const container = document.querySelector("body")
  container.scrollTo(0, 0);
  window.addEventListener('resize', handleResize);
  updateLayout();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
.waterfall-wrapper {
  margin-bottom: 60px; /* 为底部footer留出空间 */
  padding-bottom: 20px; /* 额外的底部间距 */
}
.waterfall-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 8px;
  min-height: 100px;
  box-sizing: border-box;
  margin-bottom: 70px;
}

.waterfall-item {
  position: absolute;
  background: #f5f7fa;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.waterfall-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  vertical-align: bottom;
}

@media screen and (max-width: 576px) {
  .waterfall-wrapper {
    margin-bottom: 80px; /* 移动端可以适当调整间距 */
  }

  .waterfall-container {
    padding: 0 4px;
  }
}
</style>