<!--素材中心搜索详情-->
<template>
  <div class="waterfall-grid">
    <div v-for="(item, index) in props.similarRecommendations" :key="index" class="waterfall-item" :style="{  width: `${item.width * 200 / item.height}px`, flexGrow: item.width / item.height * 1000, maxWidth: '500px' }">
      <NuxtLink :title="item.title" :to="'/detailsMaterialCenter/id/'+item.id+'/type/'+props.type">
          <NuxtImg  :src="item.coverimg" :alt="item.title"  class="waterfall-img" lazy/>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  similarRecommendations:Array,
  type:Number
});
// const images = ref([])
// onMounted (()=>{
//   getList()
// })
// const getList = (()=>{
//   if(props.similarRecommendations){
//     images.value = props.similarRecommendations
//   }
// })
</script>
<style lang="less" scoped>
.waterfall-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* 间距 */
  padding-bottom: 50px;
}
.waterfall-grid::after {
  content: '';
  flex-grow: 999999999;
  min-width: 200px; /* 图片可以占满一行时保证换行，否则最后一行末尾可能会出现一小段空白 */
  height: 0;
}
.waterfall-item {
  //flex: 1 0 250px; /* 初始宽度 250px，flex-grow: 1 可以自适应宽度 */
  margin-bottom: 3px; /* 底部间距 */
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.waterfall-img {
  width: 100%;
  display: block;
  transition: all 0.2s;
}
.waterfall-img:hover {
  transform: scale(1.2);
}
</style>