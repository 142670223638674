<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import detailsMaterialCenterPc  from "@/src/components/materialCenter/detailsMaterialCenterPc.vue";
import detailsMaterialCenterWap from "@/src/components/wap/materialCenter/info.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(detailsMaterialCenterPc, detailsMaterialCenterWap)
</script>
<style scoped>
body{

}
</style>